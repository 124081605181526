@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin slideUpAppear {
  animation: slideUp 0.4s ease-out;
}
