@import 'styles/helpers';

.wrap {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 64px;
  z-index: 50;

  .boxShadow {
    box-shadow: 0 16px 30px 0 rgba(102, 138, 184, 0.1);
  }

  .selector {
    display: inline-flex;
    justify-content: center;
    height: 48px;
    background-color: #f9fbfe;
    font-size: 14px;
    padding: 14px 35px 0 35px;
    width: 100%;
    left: 0;

    .option {
      margin-right: 40px;
      white-space: nowrap;
      cursor: pointer;
      position: relative;

      &:not(:hover) {
        color: #383838;
      }

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        font-weight: 600;
        color: $color-font-blue;
      }

      &.selected:after {
        content: '';
        position: absolute;
        width: 60px;
        height: 4px;
        border-radius: 2px;
        background-color: $color-font-blue;
        bottom: 0;
        left: calc(50% - 30px);
      }
    }
  }
}

:global(.mobile) {
  .wrap {
    position: sticky;
    top: 59px;
    z-index: 50;

    .selector {
      height: 0.9rem;
      width: 100%;
      border-radius: 0;
      font-size: 0.259rem;
      padding: 0.259rem 0.648rem 0 0.648rem;
      margin-top: 0;
      @include px2rem(font-size, 28);
      transition: all 0.3s;
      justify-content: start;
      overflow-x: scroll;
      // Hide Scrollbar
      &::-webkit-scrollbar {
        display: none; // Chrome, Safari and Opera
      }
      -ms-overflow-style: none; // IE and Edge
      scrollbar-width: none; // Firefox

      .option {
        margin-right: 0.445rem;

        &:last-child {
          margin-right: 0;
        }

        &.selected:after {
          width: 1.111rem;
          left: calc(50% - 1.111rem / 2);
        }
      }
    }
  }
}
