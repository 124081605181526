@mixin inner {
  width: $page-inner-width;
  margin: auto;

  :global(.mobile) & {
    width: auto;
    max-width: 100%;
    @include px2rem(padding-left, 40);
    @include px2rem(padding-right, 40);
  }
}

@mixin dllink {
  background-image: linear-gradient(-179deg, #3ac4fb 0%, #1b92f5 100%);
  box-shadow: 0 1px 2px 0 rgba(67, 67, 94, 0.2);
  border-radius: 100px;
  padding: 9px 24px;
  color: #fff;

  :global(.mobile) & {
    @include px2rem(padding, 18, 40);
    @include px2rem(border-radius, 40);
    font-weight: 600;
  }
}

@mixin dllinkHover {
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(58, 196, 251, 0.5);

  :global(.mobile) & {
    box-shadow: none;
  }
}

@mixin dllink-red {
  @include dllink;
  background: #f5222d;
}

@mixin dllinkHover-red {
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(245, 34, 45, 0.4);

  :global(.mobile) & {
    box-shadow: none;
  }
}

@mixin contentTitle {
  .title {
    font-size: 32px;
    margin: 0 0 18px;
    font-weight: 700;

    :global(.mobile) & {
      @include px2rem(font-size, 40);
      @include px2rem(margin-bottom, 30);
    }
  }

  .subtitle {
    line-height: 1.8;

    :global(.mobile) & {
      @include px2rem(width, 582);
      margin: auto;
    }
  }
}

@mixin borderScaleAnimation {
  @keyframes bd {
    to {
      opacity: 0;
      transform: scale(1.8);
    }
  }
}

@mixin innerWithFooterSmall {
  display: flex;
  min-height: calc(100vh - 260px);
  align-items: center;
}

@mixin optionWrapOpen {
  visibility: unset;
  opacity: 1;
  transform: translateY(0);
}
