@import 'styles/helpers';

.wrap {
  background-image: linear-gradient(147deg, #3ac4fb 2%, #178bec 108%);
  padding: 60px 0 40px;
}

.inner {
  @include inner;
  display: flex;
  position: relative;
}

.content {
  margin: 0 auto 68px;
  text-align: center;
}

@include contentTitle;

.title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 2;
  text-align: left;
  position: relative;
}

.subtitle {
  font-size: 24px;
}

.startWrap {
  text-align: left;
  position: relative;
}

.start {
  display: inline-block;
  padding-top: 10px;
  font-size: 18px;
  padding-left: 24px;
  padding-right: 24px;
  height: 48px;
  background-color: #fff;
  border-radius: 24px;
  font-weight: 600;
  color: $color-font-blue;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-2px);
  }
}

.launchIcon {
  height: 302px;
  width: 357px;
  opacity: 0;
  transform: translateY(12px);
  transition: 0.4s opacity, 0.4s transform;
}

.innerEnter {
  .launchIcon {
    opacity: 1;
    transform: translateY(0px);
  }
}

.launchRight {
  margin: 66px 177px 0 0;
}

.mapBg {
  position: absolute;
  bottom: -20px;
  left: -139px;
  height: 360px;
  width: 500px;
}

:global(.mobile) {
  .wrap {
    padding-top: 72px;
    padding-bottom: 54px;
  }
  .content {
    margin-bottom: 0;
  }
  .title {
    margin-bottom: 0;
    text-align: center;
  }
  .mapBg {
    display: none;
  }
  .inner {
    flex-direction: column;
  }
  .launchRight {
    margin: 0;
  }
  .launchIcon {
    margin: 0 auto;
    height: 4.23rem;
    width: 5rem;
  }
  .start {
    margin: 32px auto 16px auto;
  }
  .startWrap {
    text-align: center;
  }
}
