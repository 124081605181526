.localeImage {
  /*
    As per the default behavior in firefox,
    alt attribute is rendered first time just before the image rendering,
    which looks bit awkward as all alt attributes are displayed first.
   */
  background: transparent;
  color: transparent;

  &:-moz-loading {
    visibility: hidden;
  }
}
