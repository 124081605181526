@import 'styles/helpers';

.languageBtn {
  &:hover {
    .languageSelectOptionWrapper {
      @include optionWrapOpen;
    }
  }

  .languageSelectOptionWrapper {
    margin-top: 0;

    .languageSelectOptionWrapperInner {
      margin-top: 50px;
      box-shadow: 0 16px 30px 0 rgba(50, 66, 85, 0.1);
    }
  }
}
