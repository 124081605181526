@import 'styles/helpers';

.option {
  padding: 7px 16px;
  position: relative;
  cursor: pointer;
  display: block;
  @include transition($transdur, background-color);

  &:hover {
    background-color: rgba(58, 196, 251, 0.08);
  }

  &Selected {
    composes: option;
    cursor: default;
  }
}

.optionWrap {
  max-height: 240px;
  background-color: #fff;
  border: 1px solid $color-bd-sliver;
  border-radius: 6px;
  font-size: 13px;
  position: relative;
  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  .option {
    color: #383838;
  }

  .option:hover {
    color: #1890ff;
  }

  .options {
    width: 100%;
  }
}

.optionWrapCountryCode {
  height: 400px;
  max-height: none;
  width: 350px;
}

.countryCodeOption {
  display: flex;
  font-size: 13px;
  padding: 7px 16px;
  width: 100%;

  .prefix {
    font-weight: 600;
    width: 68px;
  }
}

.countryCodeHeader {
  padding: 3px 16px;
  font-size: 13px;
  color: rgba(32, 35, 37, 0.3);
  background-color: #f9fbfe;
  cursor: default;
  position: relative;
  display: block;
}

.indices {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 20px;
  width: 0;
  padding: 0;
  max-width: 0;
  font-size: 10px;
  height: 100%;
  color: #202325;
  text-align: center;
  transition: opacity 0.4s;

  .index {
    height: 15px;
    width: 13px;
    position: sticky;
    opacity: 0.3;
  }

  .index:hover {
    opacity: 1;
  }
}

:global(.mobile) {
  .optionWrapCountryCode {
    @include px2rem(height, 400 * 2);
    @include px2rem(width, 300 * 2);
  }

  .countryCodeHeader {
    @include px2rem(padding, 3 * 2, 16 * 2);
    @include px2rem(font-size, 13 * 2);
  }

  .countryCodeOption {
    @include px2rem(font-size, 13 * 2);
    @include px2rem(padding, 7 * 2, 16 * 2);

    .prefix {
      @include px2rem(width, 68 * 2);
    }
  }

  .indices {
    @include px2rem(right, 20 * 2);
    @include px2rem(font-size, 10 * 2);

    .index {
      @include px2rem(height, 15 * 2);
      @include px2rem(width, 13 * 2);
    }
  }
}
