@import 'styles/helpers';

.errorToastContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  .errorToast {
    @include px2rem(min-height, 40 * 2);
    @include px2rem(line-height, 20 * 2);
    @include px2rem(border-radius, 4 * 2);
    @include px2rem(font-size, 14 * 2);
    @include px2rem(top, 84 * 2);
    @include px2rem(padding, 10 * 2, 16 * 2);
    @include px2rem(margin, 'auto', 20 * 2);
    text-align: left;
    color: rgba(255, 255, 255, 1);
    background: rgba(76, 90, 113, 1);
    visibility: hidden;
    position: fixed;
    // navbar is 200
    z-index: 300;
  }

  .errorToastEnter {
    visibility: visible;
    transition: all 0.2s ease-out;
  }
}
:global(.desktop) {
  .errorToastContainer {
    .errorToast {
      min-height: 40px;
      line-height: 20px;
      border-radius: 4px;
      font-size: 14px;
      top: 106px;
      padding: 10px 16px;
      margin: auto;
      max-width: 400px;
    }
  }
}
