@import 'styles/helpers';

.mobileNavigationWrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  transition: left 0.3s;
  background: white;
  z-index: 200;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .mobileNavigationHeader {
    padding: 10px 16px;
    position: relative;

    .mobileNavigationLogo {
      @include bgimg('st-home-nav-ic-logo@2x.png', 122px, 40px);
    }

    .mobileNavigationClose {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 18px;
      padding: 20px;

      .mobileNavigationCloseLine {
        position: absolute;
        top: 20px;
        right: 10px;
        border-top-width: 2px;
        border-top-style: solid;
        width: 20px;

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .mobileNavigationContent {
    padding-left: 22px;
    padding-right: 22px;
    overflow: auto;
    flex: 1;

    .mobileNavigationItem {
      border-bottom: 1px solid rgba(0, 21, 54, 0.05);
      position: relative;
      transition: all 0.5s;
      max-height: 75px;
      overflow: hidden;

      .mobileNavigationItemName {
        padding: 22px 0;
        font-size: 18px;
        font-weight: bold;
        color: #202325;
      }

      .mobileNavigationItemOpenChildren {
        @include bgimg('mobile-nav-arrow-down.png', 24px, 24px);
        position: absolute;
        right: 0;
        top: 24px;
      }

      .mobileNavigationItemChildren {
        padding-left: 20px;
        margin-bottom: 15px;

        .mobileNavigationItemChild {
          padding-top: 13px;
          padding-bottom: 13px;
          font-size: 16px;
        }
      }
    }

    .openMobileNavigationItem {
      max-height: 500px;
    }
  }
}

.openMobileNavigationWrapper {
  left: 0;
}
