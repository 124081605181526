@import 'styles/helpers';

.desktopNavbar {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.leftNavbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  margin-right: 36px;
}

.rightNavbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
}

:global(.mobile) {
  .wrap {
    display: block;
    width: 100%;
    @include px2rem(padding, 0, 0, 40);
  }

  .item {
    @include px2rem(margin, 40, 0);

    &:first-child,
    &:last-child {
      margin: 0;
    }

    &:hover {
      color: $color-font-black;
    }
  }
}
