.appWrapper {
  .main {
    position: relative;
  }
}

:global(.mobile) {
  .appWrapper {
    width: 100vw;
  }
}
