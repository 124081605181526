$browsershack: (
  'ie10+': 'screen and (-ms-high-contrast: active), (-ms-high-contrast: none)',
  'chrome': screen and
    (
      -webkit-min-device-pixel-ratio: 0,
    ),
  'ff': screen and
    (
      -moz-images-in-menus: 0,
    ),
  'winff': 'screen and (-moz-windows-theme)',
  'edge': '(-ms-ime-align:auto)',
);

$easing: (
  ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1),
  ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7),
  ease-out: cubic-bezier(0.215, 0.61, 0.355, 1),
  ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1),
  ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46),
  ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6),
  ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46),
  ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1),
  ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34),
  ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86),
  ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
);

$basefonts: 'Helvetica Neue', Helvetica, arial;
$webfont: 'Open Sans';
$fonts: $webfont, $basefonts;

$transdur: 0.3;

$remratio: 100;

$page-inner-width: 1128px;

$color-font-black: #383838;
$color-font-greenblack: #2e354c;
$color-font-blue: #1b92f5;
$color-font-blue-40: rgba(27, 146, 245, 0.4);
$color-font-lightblue: #54a6eb;
$color-bd-gray: rgba(115, 115, 115, 0.1);
$color-bd-sliver: rgba(56, 56, 56, 0.2);
$color-muted-black: #202325;
$color-light-gray: rgba(0, 21, 54, 0.1);

$download-channels: appstore, playstore, huaweistore;
$download-channels-full: appstore, playstore, huaweistore, mac, macintel, macm1,
  windows, androidbundle;

$widget-pdy: 8px;

/* ---- Media Queries Breakpoints ---- */
$screen-xs: 600px;
$screen-sm: 770px;
$screen-md: 992px;
$screen-lg: 1340px;
$screen-xlg: 1640px;
