@import 'styles/helpers';

.wrap {
  background-color: #001536;
}

.inner {
  @include inner;
}

.top {
  padding: 72px 0 25px;
  display: flex;
}

.dlWrap {
  flex: 1 1 auto;
  display: flex;
}

.dl {
  margin: 5px 0 0 107px;
  flex: 0 0 auto;

  &:first-child {
    margin-left: 0;
  }
}

.dltitle {
  margin: 0 0 25px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.dlwrapper {
  margin-bottom: 10px;
}

.dllink {
  color: #fff;
  opacity: 0.7;
  font-size: 12px;
  @include transition($transdur, color);
  cursor: pointer;
  // font-weight: 300;

  &:hover {
    color: #ccc;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.logo {
  flex: 0 0 auto;
  @include bgimg('st-home-nav-ic-logo-white.png', 152px, 50px);
}

.btm {
  padding: 28px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright {
  flex: 0 0 auto;
  color: #fff;
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.languageIcon {
  height: 18px;
  width: 18px;
  margin: -4px 4px 0 -22px;
}

:global(.mobile) {
  .inner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .top {
    flex-direction: column;
    padding-top: 32px;

    .logo {
      order: 1;
    }

    .dlWrap {
      order: 2;
    }
  }

  .dlWrap {
    flex-direction: column;

    .dltitle {
      @include px2rem(font-size, 28);
    }
  }

  .logo {
    width: 2.815rem;
    height: 0.926rem;
  }

  .dl {
    margin: 32px 0 0 0;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .dlwrapper {
        @include px2rem(min-width, 220);
        @include px2rem(padding-right, 17);

        .dllink {
          @include px2rem(font-size, 24);
        }
      }

      li.longName {
        min-width: 100%;
      }
    }
  }

  .btm {
    padding-top: 6px;
  }

  .copyright {
    @include px2rem(font-size, 24);
  }
}
