.smallFooterWrapper {
  margin-top: 85px;
  margin-bottom: 20px;
  font-size: 12px;

  .smallFooterLinks {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;

    .smallFooterLink {
      color: #1b92f5;

      &:not(:first-child) {
        padding-left: 10px;
      }

      &:not(:last-child) {
        padding-right: 10px;
        border-right: 1px solid rgba(32, 35, 37, 0.1);
      }
    }
  }

  .smallFooterCopyright {
    opacity: 0.3;
    font-size: 12px;
    text-align: center;
    color: #202325;
  }
}
