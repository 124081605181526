@import 'styles/helpers';

.mobileNavbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.mobileNavbarDimmer {
  position: absolute;
  height: 200vh;
  left: 0;
  top: 100px;
  right: 0;
  background: rgba(51, 51, 73, 0.7);
  opacity: 0;
  visibility: hidden;
  @include transition($transdur, opacity, visibility);
}

.mobileNavbarListItem {
  display: block;
  width: 100%;
  @include px2rem(padding, 57, 0);
  @include px2rem(font-size, 32);
  position: relative;
  transform: translateY(-100%);
  @include transition($transdur, transform);
  background-color: white;

  .mobileNavbarItem {
    @include px2rem(margin, 0, 0, 45);
    color: #383838;
    display: block;
    transition: color 0.4s, font-weight 0.4s;

    &:last-child {
      margin: 0;
    }
  }
}

.mobileNavbarOpen {
  .mobileNavbarDimmer {
    opacity: 1;
    visibility: visible;
  }

  .mobileNavbarListItem {
    transform: translateY(82px);
  }
}

.activeItem {
  color: $color-font-blue;
  font-weight: 600;
}

.dl {
  display: flex;
  align-items: center;
  justify-content: center;

  @each $c in $download-channels {
    &#{$c} {
      @include px2rem(width, 40);
      @include px2rem(height, 40);
      @include px2rem(margin, 0, 10, 0, 0);
      background-image: url(/img/nav_ic_#{$c}_black.png);
      background-size: 100% 100%;
    }
  }
}

.coloredBg {
  .list {
    background: #178bec;
  }

  .item {
    color: #fff;
  }

  .activeItem {
    font-weight: 600;
  }

  &.mobileNavbarTriggerIcon {
    color: #fff;
  }
}

.mobileNavbarTriggerIcon {
  width: 20px;
  height: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  color: #202325;
  top: 2px;
  right: 5px;

  .mobileNavbarTriggerIconLine {
    border-top-width: 2px;
    border-top-style: solid;
    width: 100%;
    margin-bottom: 5px;
  }
}
