@import 'styles/helpers';

$transdur: 0.3s;

.header {
  padding: 11px 40px;
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  min-width: 1260px;
  transition: $transdur background-color;

  .headerLogo {
    cursor: pointer;
    @include bgimg('st-home-nav-ic-logo@2x.png', 128px, 42px);
    transition: 0.2s background-image;
    flex: 0 0 auto;
    display: block;
  }

  .headerInner {
    @include inner;
    display: flex;
    align-items: center;
    background-color: transparent;
  }
}

.disabledNavItemHeader {
  + * {
    padding-top: 100px !important;

    > * {
      padding-top: 0 !important;
    }
  }

  .headerInner {
    width: 100vw !important;
  }

  .headerLogo {
    width: 183px;
    height: 60px;
  }
  padding: 20px 32px;
}

.transparentHeader {
  composes: header;
  background-color: transparent;

  .headerLogo {
    @include bgimg('st-home-nav-ic-logo-white.png', 128px, 42px);
  }

  .navBarItem {
    color: #fff;

    &:hover {
      color: #e7e8e9;
    }

    .navBarItemArrowBlue,
    .navBarItemArrow {
      @include bgimg('st-home-nav-ic-arrow@2x.png', 18px, 18px);
    }

    .navBarItemGlobeIcon {
      @include bgimg('st-home-nav-ic-translate.png', 18px, 18px);
    }
  }

  .getStartedBtn {
    background-image: linear-gradient(#fff, #fff);
    color: #1b92f5 !important;
  }

  .externalAppBtn {
    color: white;
    background-color: transparent;
    border: 1px solid white;
  }
}

.navBarItem {
  color: #383838;
  font-size: 14px;
  margin: 0 0 0 32px;
  @include transition(0.2, color);
  display: block;
  position: relative;

  .navBarItemArrow {
    @include bgimg('st-home-nav-ic-arrow-black.png', 18px, 18px);
    margin: 1px 0 0 4px;
    transition: 0.1s background-image;
  }

  .navBarItemArrowBlue {
    @include bgimg('st-home-nav-ic-arrow-blue.png', 18px, 18px);
    margin: 1px 0 0 4px;
    transition: 0.1s background-image;
  }

  .navBarItemGlobeIcon {
    @include bgimg('st-home-nav-ic-translate-black.png', 18px, 18px);
    margin: 1px 6px 0 0;
    transition: 0.1s background-image;
  }

  &:hover {
    color: $color-font-blue;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    border-top: 2px solid currentColor;
    opacity: 0;
    @include transition($transdur, opacity);
  }
}

.navBarButton {
  composes: navBarItem;
  height: 40px;
  text-align: center;
  padding: 10px 24px;
  border-radius: 21px;
  margin-left: 32px;
  color: #fff;
  font-weight: 600;
  transition: 0.2s all !important;
}

.externalAppBtn {
  composes: navBarButton;
  border: 1px solid #1b92f5;
  background-color: white;
  color: #1b92f5;
  display: flex !important;
  align-items: center;
}

.getStartedBtn {
  composes: navBarButton;
  background-image: linear-gradient(139deg, #3ac4fb 10%, #178bec 92%);
  margin-left: 20px;

  &:hover {
    @include dllinkHover;
    transform: translateY(-2px);
  }
}

:global(.mobile) {
  .wrap {
    min-width: 0;
    padding: 0;
  }

  .header,
  .transparentHeader {
    min-width: unset;
    padding: 0;
    transition: all 0.3s;

    /* Fix UI issue in iPhone 6 */
    &,
    &::before,
    &::after,
    *,
    *::before,
    *::after {
      box-sizing: unset !important;
    }
  }

  .headerInner {
    width: 100% !important;
    z-index: 1;
    padding: 10px 20px;
    transition: $transdur background-color;
  }

  .headerLogo {
    height: 40px;
    width: 121.6px;
  }
}
