@use "sass:math";
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import './multiLangImages.scss';

@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin htxt {
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

@mixin inbw {
  font-size: 0;
}

@mixin inb($v: middle) {
  display: inline-block;
  vertical-align: $v;
}

@mixin ellipsis($to: n) {
  overflow: hidden;
  white-space: nowrap;
  @if $to == e {
    text-overflow: ellipsis;
  }
}

@mixin triangle(
  $dir: left,
  $color: #000,
  $bdt: 4px,
  $bdr: $bdt,
  $bdb: $bdt,
  $bdl: $bdt
) {
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
  border-top: solid $bdt;
  border-bottom: solid $bdb;
  border-left: solid $bdl;
  border-right: solid $bdr;
  border-color: transparent;
  border-#{$dir}-color: $color;
}

@mixin browser($name) {
  @if $name == edge {
    @supports #{map-get($browsershack, $name)} {
      @content;
    }
  } @else {
    @media #{map-get($browsershack, $name)} {
      @content;
    }
  }
}

@mixin iphonex($orientation: null) {
  @supports (padding: constant(safe-area-inset-top)) {
    @if $orientation {
      @media only screen and (orientation: $orientation) {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@mixin px2rem($prop, $values...) {
  $max: length($values);
  $remValues: '';

  @for $i from 1 through $max {
    $value: nth($values, $i);

    @if (type-of($value) == string) or ($value == 0) {
      $remValues: #{$remValues + $value};
    } @else {
      $remValues: #{$remValues + math.div($value, $remratio)}rem;
    }

    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }
  #{$prop}: $remValues;
}

@mixin px2vw($width, $prop, $values...) {
  $max: length($values);
  $vwValues: '';

  @for $i from 1 through $max {
    $value: nth($values, $i);

    @if (type-of($value) == string) or ($value == 0) {
      $vwValues: #{$vwValues + $value};
    } @else {
      $vwValues: #{$vwValues + $value / ($width / 100)}vw;
    }

    @if $i < $max {
      $vwValues: #{$vwValues + ' '};
    }
  }
  #{$prop}: $vwValues;
}

@mixin transition($dur, $props...) {
  @if ($dur > 0) {
    $max: length($props);
    $transVal: '';

    @for $i from 1 through $max {
      $value: nth($props, $i);

      $transVal: #{$transVal + $value + ' ' + $dur + 's'};
      @if $i < $max {
        $transVal: #{$transVal + ','};
      }
    }
    transition: $transVal;
  }
}

@mixin buttonEffect {
  transition: transform 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s,
    -webkit-transform 0.3s;

  button:hover {
    transform: translateY(-2px);
  }

  button:disabled {
    cursor: default;

    &:hover {
      transform: none;
    }
  }
}

@mixin titleMobile {
  color: $color-muted-black;
  font-weight: 700;
  text-align: center;
  @include px2rem(font-size, 24 * 2);
}

@mixin titleDesktop {
  font-size: 32px;
  line-height: 48px;
  color: $color-muted-black;
  font-weight: 700;
  text-align: center;
}

@mixin phcol($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin bgimg($src, $width, $height, $bgsize: '100% 100%') {
  background: url(/img/#{$src});
  background-size: #{$bgsize};
  width: $width;
  height: $height;

  @if index($multi-lang-images, $src) {
    div[lang='vi'] & {
      background: url(/img/vi/#{$src});
      background-size: #{$bgsize};
    }

    html:not(.mobile) {
      width: $width;
      height: $height;
    }
  }
}
