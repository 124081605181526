.downloadErrorToast {
  width: 245px;
  height: 40px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  position: fixed;
  top: 84px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 15px);
  visibility: hidden;
}

.downloadErrorToastEnter {
  transform: translate(-50%, 0);
  visibility: visible;
  transition: all 0.2s ease-out;
}

:global(.desktop) {
  .downloadErrorToast {
    background: rgba(0, 0, 0, 0.6);
  }
}
