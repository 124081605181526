@import 'styles/helpers';

.externalAppBtn {
  &:hover {
    .externalAppSelectOptionWrapper {
      @include optionWrapOpen;
    }
  }

  .externalAppSelectOptionWrapper {
    width: auto !important;
    margin-top: 0 !important;
    top: 39px;
    left: auto;
    margin-left: -24px !important;

    .externalAppSelectOptionWrapperInner {
      margin-top: 20px !important;
      border-radius: 8px !important;
      box-shadow: 0 16px 30px 0 rgba(50, 66, 85, 0.1);
    }
  }
}

// Support ipad
@media only screen and (max-device-width: 1400px) {
  .externalAppBtn {
    .externalAppSelectOptionWrapper {
      right: 0;
    }
  }
}

.appItem {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  position: relative;
  width: 360px;

  .appItemImage {
    margin-right: 16px;

    &.oaImage {
      @include bgimg('oa-logo.png', 40px, 40px);
    }

    &.seatalkWebImage {
      @include bgimg('seatalk-web-logo.png', 40px, 40px);
    }

    &.sopImage {
      @include bgimg('sop-logo.png', 40px, 40px);
    }
  }

  .appItemContent {
    .appItemTitle {
      font-size: 14px;
      font-weight: bold;
      color: #202325;
      text-align: left;
    }

    .appItemSubTitle {
      font-size: 14px;
      font-weight: normal;
      color: rgba(32, 35, 37, 0.7);
      text-align: left;
    }
  }

  .appItemArrow {
    @include bgimg('external-app-arrow.png', 16px, 16px);
    position: absolute;
    right: 0;
    top: 18px;
  }
}
