@import 'styles/helpers';

.popOver {
  cursor: pointer;
  display: flex !important;

  .optionWrap {
    position: absolute;
    margin-top: 10px;
    width: 175px;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s ease 0s;
    border-radius: 3px;
    visibility: hidden;
    transform: translateY(-10px);
  }
}

.touchDevicePopOver {
  .optionWrap {
    opacity: 0 !important;
    transform: translateY(-10px) !important;
    visibility: hidden !important;
  }
}

.touchDevicePopOverOpen {
  .optionWrap {
    visibility: unset !important;
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}
