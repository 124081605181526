@import 'styles/helpers.scss';

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  border: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var,
b {
  font-weight: normal;
  font-style: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}
q {
  &:before,
  &:after {
    content: '';
  }
}
acronym {
  border: 0;
}
a {
  text-decoration: none;
  color: #383838;
}
input,
button,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: middle;
}
input,
button,
select,
textarea {
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input {
  color: inherit;
}
html {
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.wrapper {
  overflow: hidden;
  font: 16px/1.5 $fonts;
  color: $color-font-black;
  min-width: 1260px;
  margin: auto;
  display: contents;
  -webkit-overflow-scrolling: touch;
}
.video-js {
  .vjs-big-play-button {
    display: none !important;
  }

  .vjs-poster {
    background-color: white !important;
  }
}
.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mobile {
  font-size: 13.334vw;

  .wrapper {
    min-width: 0;
    @include px2rem(font-size, 28);
  }
}
.display-none {
  display: none !important;
}

body {
  overflow-x: hidden;
}

#announcement-banner {
  position: relative;
  z-index: 5;
  background-color: lightyellow;

  width: 100%;
  padding: 1em;

  p {
    color: black;
    font-size: 14px;
    margin-bottom: 0.5em;
  }

  p.title {
    color: red;
    font-weight: 700;
    font-size: 18px;
  }

  strong {
    font-weight: 700;
  }
}
