@import 'styles/helpers';

.featureSelectItem {
  &:hover {
    .featureSelectOptionWrapper {
      @include optionWrapOpen;
    }
  }

  .featureSelectOptionWrapper {
    margin-left: -170px;
    width: auto !important;
    margin-top: 20px;

    .featureSelectOptionWrapperInner {
      margin-top: 30px !important;
      background-color: white;
      border-radius: 8px !important;
      box-shadow: 0 16px 30px 0 rgba(50, 66, 85, 0.1);
      display: flex;
      align-items: flex-start;
      width: auto !important;
      padding: 15px 10px 25px;
    }

    .groupTitle {
      font-weight: bold;
      padding-top: 11px;
      margin-left: 18px;
      margin-bottom: 6px;
      padding-bottom: 11px;
      border-bottom: 1px solid rgba(0, 21, 54, 0.1);
      font-size: 18px;
      color: #202325;
    }

    .optionsWrapper {
      width: 210px;
      padding-left: 5px;
      margin-right: 15px;

      .options {
        border: none;
        max-height: none;
      }
    }
  }
}
